<template>
  <div>
    <el-card>
      <div class="more_title">{{ app_name }}</div>
      <div class="more_form">
        <el-form :model="appForm" label-width="160px" ref="appForm">
          <el-form-item label="系统名称：" prop="app_name">
            <div class="more_input">
              <el-input v-model="appForm.app_name"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="系统AppId：" prop="app_id">
            <div class="more_input">
              <div class="more_hide" @click.stop="hideMore($event)">
                已隐藏内容，点击查看或编辑
              </div>
              <el-input v-model="appForm.app_id" readonly></el-input>
            </div>
          </el-form-item>
          <el-form-item label="系统AppKey：" prop="app_key">
            <div class="more_input">
              <div class="more_hide" @click.stop="hideMore($event)">
                已隐藏内容，点击查看或编辑
              </div>
              <div class="d-flex align-items-center">
                <el-input v-model="appForm.app_key" readonly></el-input>
                <el-button @click.stop="replacement">重置</el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="系统链接：">
            <div class="more_input">
              <div class="more_hide" @click.stop="hideMore($event)">
                已隐藏内容，点击查看或编辑
              </div>
              <el-input v-model="appForm.app_url"></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <diy-footer-button>
      <el-button type="primary" @click.stop="preserve" :loading="caijiLoading"
        >保存</el-button
      >
    </diy-footer-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      app_name: this.$route.query.name,
      site_name: this.$route.query.site,
      app_id: this.$route.query.id,
      appForm: {
        app_name: "",
        app_id: "",
        app_key: "",
        app_url: "",
      },
      caijiLoading: false,
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      let that = this;
      let params = {
        app_name: this.app_name,
        site_name: this.site_name,
      };
      this.$get(this.$apis.interviewDetails + this.app_id, params)
        .then((res) => {
          if (res.code == 200) {
            this.$set(this, "appForm", {
              app_name: res.data.app_name,
              app_id: res.data.app_id,
              app_key: res.data.app_key,
              app_url: res.data.app_url,
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    // 重置
    replacement() {
      let that = this;
      this.$confirm("确认是否要重置此Key？", "提示")
        .then((e) => {
          that
            .$confirm("如要重置，请随后在" + this.app_name + "系统填写该Key")
            .then((e) => {
              that.resetKey();
            })
            .catch((e) => {});
        })
        .catch((e) => {});
    },
    resetKey() {
      this.$post(this.$apis.interviewReset + this.app_id)
        .then((res) => {
          if (res.code == 200) {
            that.getDetails();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    // 保存
    preserve() {
      let that = this;
      this.$refs["appForm"].validate((valid) => {
        if (valid) {
          that.submitPre();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitPre() {
      let that = this;
      let params = {
        app_name: this.appForm.app_name,
        app_url: this.appForm.app_url,
      };
      this.$post(this.$apis.interviewDetails + this.app_id, params)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "设置成功",
              type: "success",
            });
            setTimeout(() => {
              that.$router.go(-1);
            }, 2000);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    hideMore(e) {
      e.target.style = "display:none";
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-input {
  width: 100%;
}
.more_title {
  font-size: 16px;
  font-weight: 600;
}
.more_form {
  margin-top: 20px;
}
.more_input {
  position: relative;
  width: 378px;
  .more_hide {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    color: #636c72;
    z-index: 3;
    line-height: 2;
  }
}
</style>